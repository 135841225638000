<template>
        <section class="content-header" style="margin-top: -40px;">
            <div class="row ">
                <div class="col-md-8">
                    <trabajosrealizadostotales 
                        :actualizartotales = "actualizartotales"
                        :recargar="recargar" 
                        :id="id" 
                        :totalrent="totalrent" 
                        :servicio="servicio" 
                        :totalcliente="totalcliente" 
                        :totaloperario="totaloperario" 
                        :operarios="operarios" /> 
                </div>
                <div class="col-md-4 text-right">
                    <div class="row">
                        <div class="col-md-8">   
                            <div class="btn-group btn-group-toggle btn-sm" data-toggle="buttons" :title="$t('general.filtrarpor')">
                                <label class="btn btn-outline-secondary" :class="{ 'active': selectedOption === 'option_b3' }">
                                    <input type="radio" name="options" id="option_b3" value="option_b3" v-model="selectedOption" @change="mostrartodo()" checked autocomplete="off"> {{$t('general.todos')}}
                                </label>
                                <label class="btn btn-outline-secondary" :class="{ 'active': selectedOption === 'option_b1' }">
                                    <input type="radio" name="options" id="option_b1" value="option_b1" v-model="selectedOption" @change="togglecliente()" autocomplete="off"> {{$t('general.a cliente')}}
                                </label>
                                <label class="btn btn-outline-secondary" :class="{ 'active': selectedOption === 'option_b2' }">
                                    <input type="radio" name="options" id="option_b2" value="option_b2" v-model="selectedOption" @change="toggleoperario()" autocomplete="off"> {{$t('general.a operario')}}
                                </label>
                            </div>
                        </div>
                        <div :class="facturaClass" class="col-md-4" style="margin: 0; height: 42px; display: flex; justify-content: center; align-items: center">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" v-model="checkfacturar" class="custom-control-input" @change="comprobarFacturar()" id="para_facturar">
                                <label class="custom-control-label" for="para_facturar">{{ $t('general.para facturar')}}</label>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
         <div class="col-md-12" style="display: flex; flex-flow: row; justify-content: space-between; margin-bottom: 15px;" >
            <!--<div title="Marcar todos los trabajos" class="card" style="margin: 0;width: 300px; height: 42px;">
                <div class="card-body" style="display: flex; justify-content: space-between; align-items: center; padding-right: 0;">
                    <div style="display: flex; align-items: center; gap: 10px;">
                        <input style="width: 17px; height: 17px;" type="checkbox" v-model="marcadosTodos" @change="marcarTodos()">
                        <label style="margin: 0">Marcar todos</label>
                    </div>
                    <button type="button" class="btn btn-secondary btn-sm" title="Subsanar todos los trabajos marcados"
                            @click="subsanarTrabajos()"><i class="fas fa-tools"></i>&nbsp;Subsanar</button>
                </div>
            </div>-->
            <Button type="button" v-if="operariostrabajo !='' && mostrarboton==true" @click="mostrarnuevooperario = !mostrarnuevooperario" style="font-size:small;" title="Nuevo trabajo" class="btn btn-info">Nuevo trabajo de operario con visita</Button>
           <!-- <Button type="button" v-if="proveedoreslista.length>0"  @click="mostrarnuevoproveedor = !mostrarnuevoproveedor"  title="Añadir proveedor" class="btn btn-warning">Faltan Proveedores por añadir</Button> -->
         </div>
         <div class="col-md-12">
            <select style="width:25%" v-if="mostrarnuevoproveedor" @change="mostrarseleccionado()" class="form-control" v-model="proveedorseleccionado">
                        <option hidden selected disabled value="">Selecciona Proveedor</option>
                        <option v-for="proveedor in proveedoreslista" :key="proveedor.id" :value="proveedor.id">{{ proveedor.nombre }}</option>
            </select>
            <br>
         </div>
         <div class="col-md-12" v-if="proveedorseleccionado && mostrarnuevoproveedor">
            <TrabajoRealizadoNuevoProveedor :id="id" :proveedor="proveedorseleccionado"  :operariostrabajo="operariostrabajo" :servicio="servicio">
            </TrabajoRealizadoNuevoProveedor>
            <p>&nbsp;</p> 
         </div>
         <div class="col-md-12" v-if="mostrarnuevooperario && mostrarboton==true">
            <TrabajoRealizadoNuevoOperario :id="id"  :operariostrabajo="operariostrabajo" :servicio="servicio">
            </TrabajoRealizadoNuevoOperario>
            <p>&nbsp;</p> 
         </div>
         <div class="card card-warning" v-if="!haytrabajos">
            <div class="card-header" style="border-bottom-left-radius: 0.25rem; border-bottom-right-radius: 0.25rem;text-align: center;">
                <h5>Para agregar trabajos debe crear primero una cita.</h5>
            </div>
         </div>
         <div v-else @click="console.log('operarios', operarios)">
             <div class="col-md-12">     
                 <trabajosrealizadosoperarios 
                    ref="trabajosoperarios"
                    :actualizartotales="actualizartotales"
                     :recargar="recargar" 
                     :servicio="servicio" 
                     :totalrent="totalrent" 
                     :ocultarcliente="ocultarcliente" 
                     :ocultaroperario="ocultaroperario" 
                     :totalcliente="totalcliente" 
                     :totaloperario="totaloperario"  
                     :id="id" 
                     :operarios="operarios"/>
             </div>
             <div class="col-md-12">            
                 <trabajosrealizadosproveedores
                 ref="reftrabajosproveedores"
                 :actualizartotales="actualizartotales"
                     :recargar="recargar" 
                     :servicio="servicio" 
                     :totalrent="totalrent" 
                     :ocultarcliente="ocultarcliente" 
                     :ocultaroperario="ocultaroperario" 
                     :totalcliente="totalcliente" 
                     :totaloperario="totaloperario" 
                     :id="id" 
                     :proveedoreslista="proveedoreslista"
                     :operarios="operarios"
                     :trabajosproveedores="trabajosproveedores"
                     :cargado="cargado"/>
             </div>
         </div>
        </section>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import TrabajoRealizadoNuevoOperario from './TrabajoRealizadoNuevoOperario.vue';
import trabajosrealizadosoperarios from './TrabajosRealizadosOperario.vue';
import trabajosrealizadosproveedores from './TrabajosRealizadosProveedor.vue';
import trabajosrealizadostotales from './TrabajosRealizadosTotales.vue';
import TrabajoRealizadoNuevoProveedor from './TrabajoRealizadoNuevoProveedor.vue'
export default ({
    props:['id','servicio','actualizarfacturado','auxtrabajos'],
    setup() {
        return;
    },
    components: {
       trabajosrealizadosoperarios,
        trabajosrealizadostotales,
        trabajosrealizadosproveedores,
        TrabajoRealizadoNuevoOperario,
        TrabajoRealizadoNuevoProveedor
    },
    data() {
         return {  
            marcadosTodos:false,
            actualizartotales:false,
            ocultarcliente: false,
            ocultaroperario: false,
            colorFactura: '',
            checkfacturar: false,
            test: '',
            recargar: false,
            mostrarnuevooperario:false,
            selectedOption: 'option_b3', 
            operarios: [] ,// Agregar esta línea
            operariostrabajo: '',
            haytrabajos: false,
            mostrarboton: false,
             mostrarnuevoproveedor: false,
             trabajosproveedores: '',
            proveedoreslista:[],
             cargado: false,
             proveedorseleccionado:'',
        };
    },
    methods: {
        subsanarTrabajos(){
            this.$refs.trabajosoperarios.subsanarTodos();
        },
        mostrarseleccionado(){
            console.log("proveedorseleccionado", this.proveedorseleccionado);
        },
        marcarTodos(){
            if(this.marcadosTodos){
                this.$refs.trabajosoperarios.cambiarMarcadoTrue();
            }else{
                this.$refs.trabajosoperarios.cambiarMarcadoFalse();
            }
            console.log(this.marcadosTodos);
        },
        async cargarproveedores() {
            this.proveedoreslista = []
            const api = new PwgsApi();
            let aux = await api.get('servicios/' + this.$props.id + '/pedidos');
            console.log("proveedorestrabajo", Object.values(this.trabajosproveedores));
            for (let i = 0; i < aux.datos.length; i++) {
                let repetido = false
                for (let j = 0; j < this.trabajosproveedores.length; j++){
                    if (aux.datos[i].proveedores_idproveedor == this.trabajosproveedores[j]) {
                        repetido = true;
                    }
                 
                }
                if (repetido == false) {
                    let subidadatos = { id: aux.datos[i].proveedores_idproveedor, nombre: aux.datos[i].nombre_proveedor }
                    this.proveedoreslista.push(subidadatos);
                }
            }
            console.log("proveedoreslista", this.proveedoreslista);

        },
        togglecliente() {
            this.selectedOption = 'option_b1'
            this.ocultarcliente = false;
            this.ocultaroperario = true;
        },
        toggleoperario() {
            this.selectedOption = 'option_b2'
            this.ocultaroperario = false;
            this.ocultarcliente = true;
        },
        mostrartodo() {
            this.selectedOption = 'option_b3'
            this.ocultarcliente = false;
            this.ocultaroperario = false;
        },
        togglerecargar(idoper) {
            console.log('idoper', idoper);
            if(idoper && idoper.proveedores_id_proveedor == '0'){
                this.cargardatos(idoper, 'operario');
            }else{
                this.cargardatos(idoper, 'proveedor');
            }
        },
        async cargardatos(idoper, tipo) {
            const api = new PwgsApi();
            
            var opers = await api.get('trabajos/' + this.id + '/trabajos-servicio');
            this.colorFactura = opers.color_factura ? opers.color_factura : '';
            //console.log('datosactu',idoper ,opers, this.operarios);
            if(opers.proveedor){
                this.trabajosproveedores = Object.keys(opers.proveedor);
            }
            this.cargado = !this.cargado;
            console.log("cargado", this.cargado);
            if(idoper){
                //var opercambio = {};
                /*for(let oper of Object.entries(opers.operario)){
                    if(oper[0] == idoper.operarios_idoperario){
                        opercambio.id = oper[0];
                        opercambio.datos = oper[1];
                    }
                }*/
               if(tipo == 'operario'){
                   this.operarios.operario[idoper.operarios_idoperario] = opers.operario[idoper.operarios_idoperario];
               }else{
                    console.log('va a cambiar', this.operarios, idoper);
                    console.log('va a cambiar2', opers);
                   this.operarios.proveedor[idoper.proveedores_id_proveedor] = opers.proveedor[idoper.proveedores_id_proveedor];
               }
                this.operarios.totales = opers.totales;
                console.log('datosac resultados',this.operarios);
                if(tipo == 'operario'){
                    this.$refs.trabajosoperarios.actualiztotales(idoper.operarios_idoperario, this.operarios);
                }else{
                    this.$refs.reftrabajosproveedores.actualiztotales(idoper.proveedores_id_proveedor, this.operarios);
                }
                this.actualizartotales = !this.actualizartotales;
            }else{
                this.operarios = opers;
                console.log('llegaoper', this.operarios);
            }
            this.cargaroperarios();
            this.cargarproveedores();
            this.mostrarnuevoproveedor = false;

        },
        comprobarFacturar(){
            if(this.checkfacturar == true && this.operarios.totales.rentabilidad_cia > this.operarios.totales.beneficio.porcentaje){
                this.confirmarFacturar();
            }else{
                this.facturar();
            }
        },
        confirmarFacturar() {
            this.$confirm.require({
                message: 'El expediente está por debajo de la rentabilidad configurada: '+this.operarios.totales.rentabilidad_cia+'%. ¿Desea marcar el servicio para facturar?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'Cancelar',
                acceptLabel: 'Aceptar',
                accept: () => {
                    this.facturar();
                },
                reject: () => {
                    this.checkfacturar = false;
                    return;
                }
            });
        },
        async facturar() { 
            const api = new PwgsApi();
            //alert("facturar "+this.checkfacturar);
            const subidadatos = { "facturar_servicio": this.checkfacturar ? 1 : 0 };
                try {
                    console.log('facturarrr', this.checkfacturar, this.operarios);                    
                    this.test = await api.put('trabajos/' + this.$props.id + '/facturar-servicio', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                catch (error) {
                    this.checkfacturar = false;
                    this.$toast.add({ severity: 'error', summary: "Acción no realizada", detail: error, life: 5000 });
                }
        },
        async cargaroperarios() {
            const api = new PwgsApi();
            let aux = await api.get('trabajos/' + this.$props.id + '/operarios-sin-trabajos');
            this.operariostrabajo = aux.operarios_sin_trabajos;
            //console.log("operariossintrabajos", aux);
            this.mostrarboton = true;
            let aux2 = await api.get('trabajos/' + this.$props.id + '/hay-trabajos');
            this.haytrabajos = aux2.hay_trabajos;
        },
        comprobarcheck(){
            if(this.servicio){
                console.log('compruebacheck', this.servicio);
                if (typeof this.servicio.facturar_servicio !== 'undefined') {                
                    this.checkfacturar = this.servicio.facturar_servicio == "1";
                }
                 
            }
        }
    },
    computed: {
        facturaClass() {
            switch (this.colorFactura) {
                case 'FondoVerde':
                    return 'col-md-4 bg-success disabled card';
                case 'FondoRojo':
                    return 'col-md-4 bg-danger disabled card';
                default:
                    return 'col-md-4 card'; // por si acaso
            }
        }
    },
    watch: {
        actualizarfacturado(){
            console.log('entracargardatos');
            this.cargardatos();
        },
        cargado() {
            this.cargarproveedores();

        },
        checkfacturar(newVal) {
            //console.log(newVal);
            //alert("facturar "+this.checkfacturar);            
            // Actualiza colorFactura basado en el nuevo valor de checkfacturar
            if(this.servicio.color_factura == 'FondoVerde' || this.servicio.color_factura == 'FondoRojo'){
                this.colorFactura = newVal ? 'FondoVerde' : 'FondoRojo';
            }else{
                this.colorFactura = '';
            }
        },
        id() { 
            this.cargardatos();

        },
        recargar() {
            this.cargardatos();
        },
        servicio(){
            this.comprobarcheck();
            
            this.cargardatos();
        },
        auxtrabajos() {
            this.cargardatos();
        }
    },
    mounted() {
        // AsegÃºrate de que 'servicio' estÃ¡ disponible y tiene la propiedad 'facturar_servicio'
        this.comprobarcheck();
        
        this.cargardatos();
    },        
})
</script>
