<template>
    <div class="card" v-for="proveedor in localProveedores.proveedor" :key="proveedor">
        <div class="card-header bg-gray">
            <h3 :title="proveedor.Cliente.trabajos[0].datos.nombre_operario" class="card-title" v-if="proveedor.Cliente"><i class="fas fa-list mr-2"></i>{{ $t('general.proveedor')
                }}
                {{proveedor.Cliente.trabajos[0].datos.nombre_operario.slice(0,20)}}</h3>
            <h3 :title="proveedor.Compañia.trabajos[0].datos.nombre_operario" class="card-title" v-else-if="proveedor.Compañia"><i class="fas fa-list mr-2"></i>{{
                $t('general.proveedor') }} {{proveedor.Compañia.trabajos[0].datos.nombre_operario.slice(0,20)}}</h3>
            <h3 :title="proveedor._.trabajos[0].datos.nombre_operario" class="card-title" v-else-if="proveedor._"><i class="fas fa-list mr-2"></i>{{ $t('general.proveedor') }}
                {{ proveedor._.trabajos[0].datos.nombre_operario.slice(0,20) }}</h3>
            <h3 :title="proveedor.operario.trabajos[0].datos.nombre_operario" class="card-title" v-else-if="proveedor.operario"><i class="fas fa-list mr-2"></i>{{
                $t('general.proveedor') }} {{ proveedor.operario.trabajos[0].datos.nombre_operario.slice(0,20) }}</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" title="Subsanar todos operario" style="color:white;"
                    @click="subsanarTrabajos(proveedor)"><i class="fas fa-tools"></i>&nbsp;Subsanar</button>
                <button type="button" class="btn btn-tool" title="Revisar todos operario" style="color:white;"
                    @click="revisarTrabajos(proveedor)"><i class="fas fa-check-double"></i>&nbsp;Revisar</button>
                <button type="button" class="btn btn-tool" title="Marcar facturar operario" style="color:white;"
                    @click="facturarTrabajos(proveedor)"><i class="fas fa-file-invoice-dollar"></i>&nbsp;Marcar
                    facturar</button>
                <button type="button" class="btn btn-tool" title="Eliminar todos los trabajos del operario"
                    style="color:white;" @click="eliminarTrabajos(proveedor)"><i
                        class="fas fa-times"></i>&nbsp;Eliminar</button>
                <button type="button" class="btn btn-tool" title="Eliminar todos los trabajos del operario al cliente"
                    style="color:white;" @click="eliminarTrabajosCliente(proveedor)"><i
                        class="far fa-times-circle"></i>&nbsp;Eliminar
                    cliente</button>
                <button type="button" class="btn btn-tool" title="Eliminar todos los trabajos del operario al operario"
                    style="color:white;" @click="eliminarTrabajosOperario(proveedor)"><i
                        class="fas fa-times-circle"></i>&nbsp;Eliminar
                    operario</button>
                <span :title="$t('general.total') + ' ' + $t('general.a cliente')" class="badge bg-success mr-2">{{
                    proveedor.totales.cliente}}&euro;</span>
                <span :title="$t('general.total') + ' ' + $t('general.a proveedor')" class="badge bg-danger mr-2">
                    {{ formattedOperarioTotal(proveedor.totales.operario.base,Math.round(proveedor.totales.operario.base-proveedor.totales.operario.contado))
                    }}</span>
                <span :title="$t('general.rentabilidad')" class="badge bg-info  mr-2">{{
                    proveedor.totales.beneficio.porcentaje}}%</span>
                <button type="button" class="btn btn-tool" style="color:white" @click="toggleImportarPresupuesto()"
                    title="Importar trabajo presupuesto">
                    <i class="fas fa-plus-square mr-1"></i> Importar
                </button>
                <button type="button" class="btn btn-tool" style="color:white" @click="toggleComponent()"
                    :title="$t('general.nuevo trabajo')">
                    <i class="fas fa-plus-square mr-1"></i> {{ $t('general.nuevo trabajo') }}
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div v-if="showComponent">
                <trabajosrealizadonuevo :id="id" :proveedor="getKeyByValue(localProveedores.proveedor,proveedor)"
                    :servicio="servicio" />
            </div>
            <div v-if="mostrarImportarPresupuesto">
                <TrabajosImportarPresupuesto :id="id" :proveedor="getKeyByValue(localProveedores.proveedor,proveedor)"
                    :servicio="servicio" @trabajos-importados="ocultarImportarPresupuesto" />
            </div>
            <div v-if="proveedor.Cliente" v-show="!ocultarcliente">
                <trabajosrealizadoconcepto ref="conc1" @cambio="cambiado" v-for="totales in proveedor.Cliente.trabajos"
                    :key="totales" :totales="totales" :id="id" :servicio="servicio" />
            </div>
            <div v-if="proveedor.Compañia" v-show="!ocultarcliente">
                <trabajosrealizadoconcepto ref="conc2" @cambio="cambiado" v-for="totales in proveedor.Compañia.trabajos"
                    :key="totales" :totales="totales" :id="id" :servicio="servicio" />
            </div>
            <!-- Renderizado de Perjudicados -->
            <div v-for="(perjudicado, index) in getPerjudicados(proveedor)" :key="index" v-show="!ocultarcliente">
                <trabajosrealizadoconcepto ref="conc3" @cambio="cambiado" v-for="totales in perjudicado.trabajos"
                    :key="totales" :totales="totales" :id="id" :servicio="servicio" />
                <div>{{ console.log('Perjudicado en template:', perjudicado) }}</div>
            </div>

            <div v-if="proveedor._" v-show="!ocultarcliente">
                <div v-for="totales in proveedor._.trabajos" :key="totales.id" class="position-relative">
                    <trabajosrealizadoconcepto ref="conc4" @cambio="cambiado" :totales="totales" :id="id"
                        :servicio="servicio" />
                </div>
            </div>
            <div v-if="proveedor.proveedor" v-show="!ocultaroperario">
                <trabajosrealizadoconceptoOperario ref="conc5" @cambio="cambiado"
                    v-for="totales in proveedor.proveedor.trabajos" :key="totales" :totales="totales" :id="id"
                    :servicio="servicio" />
            </div>

        </div>
        <div>
            <TrabajosRealizadosProveedorFooter ref="foot" :datos="proveedor" :actualizar="actualizartot"/>
        </div>
    </div>

    <div class="card" v-for="proveedor in proveedoreslistaaux" :key="proveedor.id">
        <div class="card-header bg-gray">
            <h3 class="card-title" v-if="proveedor.nombre"><i class="fas fa-list mr-2"></i>{{ $t('general.proveedor') }}
                {{ proveedor.nombre }}</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" style="color:white" @click="toggleComponent()"
                    :title="$t('general.nuevo trabajo')">
                    <i class="fas fa-plus-square mr-1"></i> {{ $t('general.nuevo trabajo') }}
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div v-if="showComponent">
                <trabajosrealizadonuevo :id="id" :proveedor="proveedor.id" :servicio="servicio" />
            </div>
        </div>
    </div>




</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import trabajosrealizadoconcepto from './TrabajoRealizadoConcepto.vue';
import trabajosrealizadoconceptoOperario from './TrabajoRealizadoConceptoOperario.vue';
import trabajosrealizadonuevo from './TrabajoRealizadoNuevoProveedor.vue';
import TrabajosImportarPresupuesto from './TrabajosImportarPresupuesto.vue';
import TrabajosRealizadosProveedorFooter from './TrabajosRealizadosProveedorFooter.vue';

export default ({
    props:[
        'id',
        'servicio',
        'recargar',
        'ocultarcliente', 
        'ocultaroperario',
        'totalrent',
        'totaloperario',
        'totalcliente',
        'mostrarfacturar',
        'operariosingular',
        'operarios',
        'proveedoreslista',
        'trabajosproveedores',
        'cargado',
        'actualizartotales'
    ],
    setup() {
        return;
    },
    components: {
        trabajosrealizadoconcepto,
        trabajosrealizadoconceptoOperario,
        trabajosrealizadonuevo,
        TrabajosImportarPresupuesto,
        TrabajosRealizadosProveedorFooter,
    },
    data() {
        return {
            cambio: false,
            actualizartot:false,
            showComponent: true,
            mostrarImportarPresupuesto: false,
            localProveedores: [],
            totalescliente: '',
            totalesoperario: '',
            totalesrentabilidad: '',
            suma: [],
            cargar: false,
            proveedoreslistaaux:[]
        };
    },
    methods: {
        toggleComponent() {
            this.showComponent = !this.showComponent;
        },
        cambiado(val) {
            console.log('cambio', val);
            this.cambio = val;
        },
        async subsanarTrabajos(operario) {
            const api = new PwgsApi();
            console.log('opererer', operario);
            var trabajos = [];
            for (let datos of Object.entries(operario)) {
                console.log(datos[0]);
                if (datos[0] != 'totales') {
                    for (let i = 0; i < datos[1].trabajos.length; i++) {
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            console.log('trabajosasubsanar', trabajos);
            if (trabajos.length > 0) {
                try {
                    if (window.confirm('¿Quiere subsanar todos los trabajos del operario?')) {
                        await api.put('trabajos/' + this.$props.id + '/subsanar', { ids_trabajos: trabajos });
                        this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos guardados para subsanar', life: 2000 });
                        this.cargardatos;
                        setTimeout(() => {
                            console.log('finalllyy');
                            for (let trabajo of trabajos) {
                                if (this.$refs.conc1) {
                                    for (let ref of this.$refs.conc1) {
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if (this.$refs.conc2) {
                                    for (let ref of this.$refs.conc2) {
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if (this.$refs.conc3) {
                                    for (let ref of this.$refs.conc3) {
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if (this.$refs.conc4) {
                                    for (let ref of this.$refs.conc4) {
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if (this.$refs.conc5) {
                                    for (let ref of this.$refs.conc5) {
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                            }
                        }, 1000);
                    }
                }
                catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }

        },
        async revisarTrabajos(operario) {
            let trabajos = [];
            for (let datos of Object.entries(operario)) {
                console.log(datos[0]);
                if (datos[0] != 'totales') {
                    for (let i = 0; i < datos[1].trabajos.length; i++) {
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            let subidadatos = { ids_trabajos: trabajos };
            const api = new PwgsApi();
            try {
                await api.put('trabajos/' + this.$props.id + '/revisar', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos guardados para revisar', life: 2000 });
                this.cargardatos;
                for (let trabajo of trabajos) {
                    if (this.$refs.conc1) {
                        for (let ref of this.$refs.conc1) {
                            ref.guardarrevisado(trabajo);
                        }
                    }
                    if (this.$refs.conc2) {
                        for (let ref of this.$refs.conc2) {
                            ref.guardarrevisado(trabajo);
                        }
                    }
                    if (this.$refs.conc3) {
                        for (let ref of this.$refs.conc3) {
                            ref.guardarrevisado(trabajo);
                        }
                    }
                    if (this.$refs.conc4) {
                        for (let ref of this.$refs.conc4) {
                            ref.guardarrevisado(trabajo);
                        }
                    }
                    if (this.$refs.conc5) {
                        for (let ref of this.$refs.conc5) {
                            ref.guardarrevisado(trabajo);
                        }
                    }
                }
            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async facturarTrabajos(operario) {
            let trabajos = [];
            for (let datos of Object.entries(operario)) {
                console.log(datos[0]);
                if (datos[0] != 'totales' && datos[0] != 'operario') {
                    for (let i = 0; i < datos[1].trabajos.length; i++) {
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            console.log('trabajos', trabajos);
            let subidadatos = { ids_trabajos: trabajos };
            const api = new PwgsApi();
            try {
                await api.put('trabajos/' + this.$props.id + '/facturar-varios', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos guardados para facturar', life: 2000 });
                this.cargardatos;
                for (let trabajo of trabajos) {
                    if (this.$refs.conc1) {
                        for (let ref of this.$refs.conc1) {
                            ref.guardarfacturar(trabajo);
                        }
                    }
                    if (this.$refs.conc2) {
                        for (let ref of this.$refs.conc2) {
                            ref.guardarfacturar(trabajo);
                        }
                    }
                    if (this.$refs.conc3) {
                        for (let ref of this.$refs.conc3) {
                            ref.guardarfacturar(trabajo);
                        }
                    }
                    if (this.$refs.conc4) {
                        for (let ref of this.$refs.conc4) {
                            ref.guardarfacturar(trabajo);
                        }
                    }
                }
            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }

        },
        async eliminarTrabajos(operario) {
            let trabajos = [];
            for (let datos of Object.entries(operario)) {
                console.log(datos[0]);
                if (datos[0] != 'totales') {
                    for (let i = 0; i < datos[1].trabajos.length; i++) {
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            if (window.confirm('¿Quiere eliminar todos los trabajos del operario?')) {
                console.log('entraeliminar')
                for (let trabajo of trabajos) {
                    console.log('entraeliminarportrabajo', trabajo)
                    if (this.$refs.conc1) {
                        for (let ref of this.$refs.conc1) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc2) {
                        for (let ref of this.$refs.conc2) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc3) {
                        for (let ref of this.$refs.conc3) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc4) {
                        for (let ref of this.$refs.conc4) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc5) {
                        for (let ref of this.$refs.conc5) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                }
            }
        },
        async eliminarTrabajosCliente(operario) {
            let trabajos = [];
            console.log('oper', operario);
            for (let datos of Object.entries(operario)) {
                console.log(datos[0]);
                if (datos[0] != 'operario' && datos[0] != 'totales') {
                    for (let i = 0; i < datos[1].trabajos.length; i++) {
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            if (window.confirm('¿Quiere eliminar todos los trabajos a clientes del operario?')) {
                console.log('entraeliminar')
                for (let trabajo of trabajos) {
                    console.log('entraeliminarportrabajo', trabajo)
                    if (this.$refs.conc1) {
                        for (let ref of this.$refs.conc1) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc2) {
                        for (let ref of this.$refs.conc2) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc3) {
                        for (let ref of this.$refs.conc3) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc4) {
                        for (let ref of this.$refs.conc4) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc5) {
                        for (let ref of this.$refs.conc5) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                }
            }
        },
        async eliminarTrabajosOperario(operario) {
            let trabajos = [];
            console.log('oper', operario);
            for (let datos of Object.entries(operario)) {
                console.log(datos[0]);
                if (datos[0] == 'operario') {
                    for (let i = 0; i < datos[1].trabajos.length; i++) {
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            if (window.confirm('¿Quiere eliminar todos los trabajos a operarios del operario?')) {
                console.log('entraeliminar')
                for (let trabajo of trabajos) {
                    console.log('entraeliminarportrabajo', trabajo)
                    if (this.$refs.conc1) {
                        for (let ref of this.$refs.conc1) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc2) {
                        for (let ref of this.$refs.conc2) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc3) {
                        for (let ref of this.$refs.conc3) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc4) {
                        for (let ref of this.$refs.conc4) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if (this.$refs.conc5) {
                        for (let ref of this.$refs.conc5) {
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                }
            }
        },
        toggleImportarPresupuesto() {
            this.mostrarImportarPresupuesto = !this.mostrarImportarPresupuesto;
        },
        ocultarImportarPresupuesto() {
            this.mostrarImportarPresupuesto = false; // Ocultar el callout de importar
            this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
            this.cargardatos(); // Volver a cargar los datos del componente padre
        },
        formattedOperarioTotal(base, contado) {
            let baseFormatted = `${base}€`;
            if (contado !== 0) {
                baseFormatted += ` (A proveedor ${contado}€)`;
            }
            return baseFormatted;
        },
        async cargardatos() { 
        //GET  modulos/pwgsapi/index.php/trabajos/:id_servicio/trabajos-servicio
            const api = new PwgsApi();
            this.localProveedores = await api.get('trabajos/' + this.$props.id + '/trabajos-servicio');            
            Object.values(this.localProveedores.proveedor).forEach(proveedor => {
                if(proveedor.Cliente){
                    var nombre1 = proveedor.Cliente.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                    proveedor.Cliente.trabajos[0].datos.nombre_operario = nombre1;
                }else if(proveedor.Compañia){
                    var nombre = proveedor.Compañia.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                    proveedor.Compañia.trabajos[0].datos.nombre_operario = nombre;
                }else if(proveedor._){
                    var nombre2 = proveedor._.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                    proveedor._.trabajos[0].datos.nombre_operario = nombre2;
                }else if(proveedor.operario){
                    var nombre3 = proveedor.operario.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                    proveedor.operario.trabajos[0].datos.nombre_operario = nombre3;
                }
            })
            //alert("Cargados desde proveedores");
            this.totalesrentabilidad = this.$props.totalcliente - this.$props.totaloperario;
            this.totalesrentabilidad = Math.round((this.totalesrentabilidad * 100).toFixed(2) / this.$props.totalcliente) + "%";
            this.cargarproveedoresaux();
        },
        async cargarproveedoresaux() {
            this.proveedoreslistaaux = []
            const api = new PwgsApi();
            let trabajosproveedoresaux = '';
            let aux = await api.get('servicios/' + this.$props.id + '/pedidos');
            var auxopers = await api.get('trabajos/' + this.id + '/trabajos-servicio');
            //console.log('datosactu',idoper ,opers, this.operarios);
            if (auxopers.proveedor) {
                trabajosproveedoresaux = Object.keys(auxopers.proveedor);
            }
               
            
            for (let i = 0; i < aux.datos.length; i++) {
                let repetido = false
                for (let j = 0; j < trabajosproveedoresaux.length; j++) {
                    if (aux.datos[i].proveedores_idproveedor == trabajosproveedoresaux[j]) {
                        repetido = true;
                    }

                }
                if (repetido == false) {
                    let subidadatos = { id: aux.datos[i].proveedores_idproveedor, nombre: aux.datos[i].nombre_proveedor }
                    this.proveedoreslistaaux.push(subidadatos);
                }
            }
           // console.log("proveedoreslista", this.proveedoreslista);
            
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        },
        togglecargar() { 
            this.cargar = !this.cargar;
        },
        getPerjudicados(operario) {
            if (!operario || typeof operario !== 'object' || Object.keys(operario).length === 0) {
                return [];
            }
            return Object.keys(operario)
                .filter(key => key.startsWith('Perjudicado_'))
                .map(key => operario[key]);
        },
        actualiztotales(id, resultados){
            console.log('llega1', id, resultados);
            var indice = Object.keys(resultados.proveedor).indexOf(id);
            console.log('indice',indice);            
            this.$refs.foot[indice].actualizatotales(id, resultados);
            this.localProveedores.proveedor[id] = resultados.proveedor[id];
        },
    },
    mounted() {
        if (this.operariosingular) {
            // Si 'operariosingular' es verdadero, cargamos los datos nosotros mismos
            this.cargardatos();
        } else if (this.operarios && Object.keys(this.operarios).length > 0) {
            // Si 'operarios' está disponible y 'operariosingular' es falso o indefinido, usamos los datos del padre
            this.localProveedores = JSON.parse(JSON.stringify(this.operarios));
            console.log('provvvss', this.localProveedores);
            if (this.localProveedores.proveedor)
            {
                Object.values(this.localProveedores.proveedor).forEach(proveedor => {
                if(proveedor.Cliente){
                    var nombre1 = proveedor.Cliente.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                    proveedor.Cliente.trabajos[0].datos.nombre_operario = nombre1;
                }else if(proveedor.Compañia){
                    var nombre = proveedor.Compañia.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                    proveedor.Compañia.trabajos[0].datos.nombre_operario = nombre;
                }else if(proveedor._){
                    var nombre2 = proveedor._.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                    proveedor._.trabajos[0].datos.nombre_operario = nombre2;
                }else if(proveedor.operario){
                    var nombre3 = proveedor.operario.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                    proveedor.operario.trabajos[0].datos.nombre_operario = nombre3;
                }
                })
            }
        }
    },
    watch: {
        actualizartotales(){
            console.log('llegaactualizartotalesprov', this.localProveedores);
            this.actualizartot = !this.actualizartot;
        },
        id() { 
            this.cargarproveedoresaux();
            this.localProveedores = [];
            this.cargardatos();
        },
        cargar() { 
            //this.cargardatos();
        },
        cargado() {
            this.cargarproveedoresaux();
        },
        recargar() {
            this.cargarproveedoresaux();

            //this.cargardatos();
        },
        operariosingular(newVal) {
            if (newVal) {
                // Si 'operariosingular' cambia a verdadero, cargamos los datos nosotros mismos
                this.cargardatos();
            } else {
                // Si 'operariosingular' cambia a falso, usamos los datos del padre si están disponibles
                if (this.operarios && Object.keys(this.operarios).length > 0) {
                    this.localProveedores = JSON.parse(JSON.stringify(this.operarios));
                    Object.values(this.localProveedores.proveedor).forEach(proveedor => {
                        if(proveedor.Cliente){
                            var nombre1 = proveedor.Cliente.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                            proveedor.Cliente.trabajos[0].datos.nombre_operario = nombre1;
                        }else if(proveedor.Compañia){
                            var nombre = proveedor.Compañia.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                            proveedor.Compañia.trabajos[0].datos.nombre_operario = nombre;
                        }else if(proveedor._){
                            var nombre2 = proveedor._.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                            proveedor._.trabajos[0].datos.nombre_operario = nombre2;
                        }else if(proveedor.operario){
                            var nombre3 = proveedor.operario.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                            proveedor.operario.trabajos[0].datos.nombre_operario = nombre3;
                        }
                    })
                }
            }
        },
        operarios(newVal) {
            if (newVal && Object.keys(newVal).length > 0 && !this.operariosingular) {
                // Si 'operarios' cambia y 'operariosingular' es falso, actualizamos 'localOperarios'
                this.localProveedores = JSON.parse(JSON.stringify(newVal));
                Object.values(this.localProveedores.proveedor).forEach(proveedor => {
                    if(proveedor.Cliente){
                        var nombre1 = proveedor.Cliente.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                        proveedor.Cliente.trabajos[0].datos.nombre_operario = nombre1;
                    }else if(proveedor.Compañia){
                        var nombre = proveedor.Compañia.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                        proveedor.Compañia.trabajos[0].datos.nombre_operario = nombre;
                    }else if(proveedor._){
                        var nombre2 = proveedor._.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                        proveedor._.trabajos[0].datos.nombre_operario = nombre2;
                    }else if(proveedor.operario){
                        var nombre3 = proveedor.operario.trabajos[0].datos.nombre_operario.replace('Proveedor ', '');
                        proveedor.operario.trabajos[0].datos.nombre_operario = nombre3;
                    }
                })
            }
        },
    }
})
</script>
